

@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');





/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tiptap.ProseMirror {
  outline: 0;
}

.prose  {
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  p {
    white-space: break-spaces;
  }
}

@keyframes spinner {
  0% { transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

.loader {
  width: inherit;
  height: inherit;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  animation: spinner 700ms infinite linear;

  &.light {
    border-color: rgba(255, 255, 255, 0.08);
    border-left-color: rgba(255, 255, 255, 0.5);
  }
}
 
@layer base {

 


  html {
    @apply flex min-h-full  ;
  }

  body {
    @apply flex-auto flex;
  }

  #root {
    @apply flex-auto flex flex-col;
  }

  main {
    .text-align-center {
      @apply text-center;
    }

    b, strong {
      @apply font-semibold;
    }

    .text-4xl,
    .text-5xl,
    .headline {
      b, strong {
        @apply font-black;
      }
    }
    // p {
    //   @apply my-1;
    // }

    .msg {

      hr{
        @apply my-6;
      }
      @apply text-base;

      // h1 {@apply text-5xl/9 leading-loose font-semibold;}
      // h2 {@apply text-5xl/5 leading-loose ;}
      h3 {
        @apply text-3xl/4 leading-loose font-black;
        strong {
          @apply font-black;
        }
      }
 
      b, strong {
        // @apply font-semibold;
      }

      p{
        @apply py-0.5;
      }

      ul, ol {
        @apply pl-6 py-1;
    
        li {
          @apply list-disc pt-1;
        }
      }

      pre {
        @apply bg-gray-700 text-white my-2 px-3 py-2 rounded-lg;
      }
      code {
        @apply text-wrap;
      }
    }
  }
}

@layer components {
  .group {
    .container {
      @apply px-0;
    }
  }

  .box {
    @apply flex-auto flex;
  }

  .col {
    @apply flex-auto flex flex-col;
  }
}
  
main {
  .text-align-center {
    @apply text-center;
  }
  .text-align-right {
    @apply text-right;
  }
 
}



